import {
  Box,
  Button,
  DarkMode,
  useDisclosure,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { IconList } from "react-fa-icon-picker";
import { auth } from "..";
import WhiteText from "../Components/WhiteText";
import { addAccount, deleteAccount } from "../dbHandler";
import { AccountDataType } from "../types";
import Card from "./Card";
import EditField from "./EditField";

const copyToastOptions = (prefix: string): UseToastOptions => {
  return {
    title: `${prefix} copied!`,
    status: "success",
    duration: 2000,
    isClosable: true,
  };
};
type AccountCardProps = {
  account: AccountDataType;
  fullW?: boolean;
  accounts: AccountDataType[];
  setAccounts: (val: AccountDataType[]) => void;
};

const AccountCard = ({
  account,
  fullW = false,
  accounts,
  setAccounts,
}: AccountCardProps) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();

  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  const name = useState(account.name);
  const username = useState(account.username);
  const password = useState(account.password);

  const [color, setColor] = useState(account.color);
  const [icon, setIcon] = useState<IconList>(account.iconId);

  const [selected, setSelected] = useState<string[]>(account.categories);

  const [showPassword, setShowPassword] = useState(false);

  const saveEdit = useCallback(() => {
    if (
      name[0] !== account.name ||
      username[0] !== account.username ||
      password[0] !== account.password ||
      color !== account.color ||
      icon !== account.iconId ||
      selected !== account.categories
    ) {
      const newAccount: AccountDataType = {
        name: name[0],
        username: username[0],
        password: password[0],
        iconId: icon,
        uid: account.uid,
        color: color,
        categories: selected,
      };
      setAccounts(
        accounts.map((value) =>
          value.uid !== newAccount.uid ? value : newAccount
        )
      );
      if (!auth.currentUser) return;
      deleteAccount(auth.currentUser.uid, account);
      addAccount(auth.currentUser.uid, newAccount);
    }
  }, [
    account,
    accounts,
    color,
    icon,
    name,
    password,
    setAccounts,
    username,
    selected,
  ]);

  const copyUsername = () => {
    navigator.clipboard.writeText(account.username);
    toast(copyToastOptions("Username"));
  };

  const copyPassword = () => {
    navigator.clipboard.writeText(account.password);
    toast(copyToastOptions("Password"));
  };

  const deleteAccountCallback = () => {
    setAccounts(accounts.filter((value) => value.uid !== account.uid));
    if (!auth.currentUser) return;
    deleteAccount(auth.currentUser.uid, account);
    setAccounts(accounts.filter((value) => value.uid !== account.uid));
  };

  useEffect(() => {
    if (!isOpen) {
      setEdit(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!edit) saveEdit();
  }, [edit, saveEdit]);

  return (
    <Card
      title={account.name}
      iconId={account.iconId}
      w={fullW ? "full" : "359px"}
      isOpen={isOpen}
      onToggle={onToggle}
      rightIcon={isOpen ? (edit ? "FaSave" : "FaEdit") : undefined}
      onIconClick={toggleEdit}
      color={account.color}
    >
      {edit ? (
        <EditField
          labels={["Name of Service", "Username", "Password"]}
          states={[name, username, password]}
          onSave={() => {
            setEdit(false);
          }}
          setColor={setColor}
          color={color}
          icon={icon}
          setIcon={setIcon}
          delete={deleteAccountCallback}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <Box>
          <WhiteText>Username: {account.username}</WhiteText>
          <WhiteText>
            Password:{" "}
            <Box
              d="inline"
              as="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? account.password : "********"}
            </Box>
          </WhiteText>
          <Box mt={2} d="flex" justifyContent="space-between">
            <DarkMode>
              <Button
                variant="outline"
                colorScheme="teal"
                rounded="none"
                onClick={copyUsername}
              >
                Username
              </Button>
              <Button
                variant="outline"
                colorScheme="teal"
                rounded="none"
                onClick={copyPassword}
              >
                Password
              </Button>
            </DarkMode>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default AccountCard;
