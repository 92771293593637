import { Box } from "@chakra-ui/react";
import { Login } from "./login";
import { IfFirebaseAuthedOr, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { Home } from "./PasswordManager/Home";
import { createContext, Dispatch, SetStateAction, useState } from "react";

export const DemoContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, () => {}]);

function App() {
  const [demo, setDemo] = useState(false);
  return (
    <Box>
      <DemoContext.Provider value={[demo, setDemo]}>
        <IfFirebaseUnAuthed>{() => (demo ? "" : <Login />)}</IfFirebaseUnAuthed>
        <IfFirebaseAuthedOr filter={(state) => state.isSignedIn || demo}>
          {() => <Home />}
        </IfFirebaseAuthedOr>
      </DemoContext.Provider>
    </Box>
  );
}

export default App;
