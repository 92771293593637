import {
  Box,
  Button,
  FormControl,
  FormLabel,
  forwardRef,
  Stack,
  StackProps,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { auth } from "./index";
import { StyledInput } from "./Components/StyledInput";
import { Navbar } from "./Components/Navbar";

const Form = forwardRef<StackProps, "form">((props, ref) => {
  return <Stack as="form" ref={ref} {...props} />;
});

export const Login = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const { colorMode } = useColorMode();

  const loginUser = () => {
    auth.signInWithEmailAndPassword(email, password);
  };

  return (
    <>
      <Box
        d="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        h="100vh"
        maxW="xl"
        mx="auto"
        px={5}
      >
        <Navbar />
        <Form
          w="full"
          maxW="xl"
          py={5}
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            loginUser();
          }}
        >
          <FormControl id="email">
            <FormLabel>E-Mail</FormLabel>
            <StyledInput
              type="email"
              mb={8}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              autoComplete="username"
              color={colorMode === "dark" ? "white" : "black"}
              borderColor={colorMode === "dark" ? "white" : "black"}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <StyledInput
              mb={8}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              type="password"
              autoComplete="password"
              color={colorMode === "dark" ? "white" : "black"}
              borderColor={colorMode === "dark" ? "white" : "black"}
            />
          </FormControl>
          <FormControl id="button">
            <Button
              bg="teal.300"
              color="black"
              _hover={{ color: "white", bg: "teal.500" }}
              w="full"
              type="submit"
            >
              Log In
            </Button>
          </FormControl>
        </Form>
      </Box>
    </>
  );
};
