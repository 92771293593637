import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Badge, Box, Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import { Slide } from "@chakra-ui/transition";
import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { auth } from "..";
import { StyledInput } from "../Components/StyledInput";
import { updateCategories } from "../dbHandler";
import { CategoryContext } from "./Home";

type CategoryEditProps = {
  isOpen: boolean;
  close: MouseEventHandler<HTMLButtonElement>;
  initialCategories: string[];
  saveCategories: (arg1: string[]) => void;
};

const CategoryEdit = ({
  isOpen,
  close,
  initialCategories,
  saveCategories,
}: CategoryEditProps) => {
  const [categories, setCategories] = React.useState(initialCategories);

  const bg = useColorModeValue("gray.200", "gray.700");

  // reset categories on Close
  useEffect(() => {
    if (!isOpen) setCategories(initialCategories);
  }, [isOpen, initialCategories]);

  return (
    <Slide in={isOpen} direction="bottom" style={{ zIndex: 100 }}>
      <Box bg={bg} rounded="md" shadow="md" h="80vh" overflow="auto">
        <Box px={5} mt="4" maxW="3xl" mx="auto" pos="relative">
          <CloseButton pos="absolute" right={5} top={0} onClick={close} />
          <Text>Categories</Text>
          <VStack mt={5}>
            {categories.map((category, index) => (
              <Flex key={index} w="full">
                <StyledInput
                  value={category}
                  onChange={(e) => {
                    setCategories(
                      categories.map((c, i) =>
                        i === index ? e.target.value.toLowerCase() : c
                      )
                    );
                    return true;
                  }}
                  onClick={(e) => e.currentTarget.focus()}
                />
                <Button
                  onClick={() => {
                    setCategories(categories.filter((c, i) => i !== index));
                  }}
                  colorScheme="red"
                >
                  Delete
                </Button>
              </Flex>
            ))}
            <Flex py={5} w="full" justify="space-between">
              <Button
                onClick={() => {
                  setCategories([...categories, ""]);
                }}
              >
                Add Category
              </Button>
              <Button
                colorScheme="green"
                onClick={() => saveCategories(categories)}
              >
                Save
              </Button>
            </Flex>
          </VStack>
        </Box>
      </Box>
    </Slide>
  );
};

type CategoriesProps = {
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  canEdit?: boolean;
};

export const Categories = ({
  selected,
  setSelected,
  canEdit = true,
}: CategoriesProps) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);

  const selectCategory = (category: string) => {
    if (selected.includes(category))
      setSelected(selected.filter((e) => e !== category));
    else setSelected([...selected, category]);
  };

  const [categories, setCategories] = useContext(CategoryContext);
  const [isDesktop] = useMediaQuery("@media only screen");

  return (
    <ScrollContainer hideScrollbars={isDesktop}>
      <HStack my={2}>
        {categories?.map((category) => (
          <Badge
            key={category}
            rounded="full"
            variant={selected.includes(category) ? "solid" : "outline"}
            py={1}
            px={3}
            as="button"
            colorScheme="gray"
            onClick={() => selectCategory(category)}
          >
            {category}
          </Badge>
        ))}
        {canEdit ? (
          <Badge
            rounded="full"
            variant="solid"
            py={1}
            px={3}
            as="button"
            colorScheme="teal"
            onClick={() => setIsEditOpen(true)}
          >
            Edit
          </Badge>
        ) : (
          ""
        )}
      </HStack>
      {canEdit ? (
        <CategoryEdit
          isOpen={isEditOpen}
          close={() => setIsEditOpen(false)}
          initialCategories={categories}
          saveCategories={(newCategories) => {
            if (setCategories) {
              setCategories(newCategories);
              if (!auth.currentUser) return;
              updateCategories(auth.currentUser.uid, newCategories);
            }
          }}
        />
      ) : (
        ""
      )}
    </ScrollContainer>
  );
};
