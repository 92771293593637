import { auth } from "..";
import { addAccount, initAccounts } from "../dbHandler";
import { v4 as uuid } from "uuid";
import { AccountDataType } from "../types";
import { Stack, useDisclosure } from "@chakra-ui/react";
import Card from "./Card";
import { useState } from "react";
import { IconList } from "react-fa-icon-picker";
import EditField from "./EditField";

const NewAccountCard = ({
  accounts,
  setAccounts,
}: {
  accounts: AccountDataType[] | undefined;
  setAccounts: (val: AccountDataType[]) => void;
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const name = useState("");
  const username = useState("");
  const password = useState("");

  const [color, setColor] = useState("#00bcd4");
  const [icon, setIcon] = useState<IconList>("FaUser");
  const [selected, setSelected] = useState<string[]>([]);

  const saveAccount = () => {
    const newAccount: AccountDataType = {
      name: name[0],
      username: username[0],
      password: password[0],
      iconId: icon,
      uid: uuid(),
      color: color,
      categories: selected,
    };
    if (!accounts || accounts.length === 0) {
      setAccounts([newAccount]);
      if (!auth.currentUser) return;
      initAccounts(auth.currentUser.uid, newAccount);
    } else {
      setAccounts([...accounts, newAccount]);
      if (!auth.currentUser) return;
      addAccount(auth.currentUser.uid, newAccount);
    }

    name[1]("");
    username[1]("");
    password[1]("");

    onToggle();
  };

  return (
    <Card
      title="New Account"
      color="green.500"
      iconId="FaPlus"
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack>
        <EditField
          onSave={saveAccount}
          labels={["Name of Service", "Username", "Password"]}
          states={[name, username, password]}
          setColor={setColor}
          color={color}
          icon={icon}
          setIcon={setIcon}
          selected={selected}
          setSelected={setSelected}
        />
      </Stack>
    </Card>
  );
};

export default NewAccountCard;
