import { Input, InputProps } from "@chakra-ui/react";
import React from "react";

export const StyledInput = (props: InputProps) => {
  return (
    <Input
      rounded="none"
      borderColor="#fff"
      _hover={{ borderColor: "#666" }}
      color="white"
      {...props}
    />
  );
};
