import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme, ThemeOverride } from "@chakra-ui/react";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCrxML5D3jQj14TqqxyqXc1h_mzm0hgRSs",
  authDomain: "securit-25055.firebaseapp.com",
  projectId: "securit-25055",
  storageBucket: "securit-25055.appspot.com",
  messagingSenderId: "589340940125",
  appId: "1:589340940125:web:bab181e4fd63538ca67ab4",
  databaseURL:
    "https://securit-25055-default-rtdb.europe-west1.firebasedatabase.app",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();

const customTheme: ThemeOverride = {
  config: { useSystemColorMode: false, initialColorMode: "dark" },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "0",
      },
    },
  },
};

const theme = extendTheme(customTheme);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <App />
      </FirebaseAuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
