import { db } from ".";
import { AccountDataType, accountQuerry } from "./types";
import firebase from "firebase";

// returns the stored accounts in firebase or an empty array
export const getAccounts = async (id: string): Promise<AccountDataType[]> => {
  const doc = await db.collection("users").doc(id).get();
  const data: accountQuerry = doc.data();
  return data?.accounts ?? [];
};

export const initAccounts = (id: string, account: AccountDataType) => {
  db.collection("users")
    .doc(id)
    .set({
      accounts: [account],
    });
};

export const addAccount = (id: string, account: AccountDataType) => {
  db.collection("users")
    .doc(id)
    .update({
      accounts: firebase.firestore.FieldValue.arrayUnion(account),
    });
};

export const deleteAccount = (id: string, account: AccountDataType) => {
  db.collection("users")
    .doc(id)
    .update({
      accounts: firebase.firestore.FieldValue.arrayRemove(account),
    });
};

export const updateCategories = (id: string, categories: string[]) => {
  db.collection("users").doc(id).update({
    categories: categories,
  });
};

export const getCategories = async (id: string): Promise<string[]> => {
  const doc = await db.collection("users").doc(id).get();
  const data = doc.data();
  return data?.categories ?? [];
};
