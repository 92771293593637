import {
  Box,
  Button,
  Circle,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { CirclePicker } from "react-color";
import { IconList, IconPicker } from "react-fa-icon-picker";
import { StyledInput } from "../Components/StyledInput";
import WhiteText from "../Components/WhiteText";
import { setInput } from "../utils";
import { Categories } from "./Categories";

type EditFieldProps = {
  onSave?: () => void;
  labels: string[];
  states: [string, Dispatch<SetStateAction<string>>][];
  setColor: (value: string) => void;
  color: string;
  icon: IconList;
  setIcon: Dispatch<SetStateAction<IconList>>;
  delete?: () => void;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
};

const EditField = (props: EditFieldProps) => {
  if (props.labels.length !== props.states.length)
    throw Error(
      "EditField Component requires props labels and states to be the same length"
    );
  else if (props.labels.length === 0) {
    throw Error(
      "EditField Component reuqires lablels and states props to have a length bigger than 0"
    );
  }

  const [isColorpickerOpen, setIsColorpickerOpen] = useState(false);

  const colorPickerChange = useCallback(
    (color) => {
      setIsColorpickerOpen(false);
      props.setColor(color.hex);
    },
    [props]
  );

  return (
    <Stack>
      <Flex w="full">
        <Box w="50%" px={1}>
          <WhiteText mb={2}>Color</WhiteText>
          <Circle
            as="button"
            size="28px"
            bg={props.color}
            onClick={() => {
              setIsColorpickerOpen(!isColorpickerOpen);
            }}
            border="solid"
            borderColor="white"
            mb={3}
          />
          {isColorpickerOpen ? (
            <CirclePicker width="100%" onChangeComplete={colorPickerChange} />
          ) : null}
        </Box>
        <Box>
          <WhiteText mb={2}>Icon</WhiteText>

          <IconPicker
            buttonStyles={{ backgroundColor: "white" }}
            containerStyles={{ borderRadius: 0 }}
            searchInputStyles={{ color: "black" }}
            value={props.icon}
            onChange={(value) => {
              props.setIcon(value);
            }}
          />
        </Box>
      </Flex>
      <Categories
        selected={props.selected}
        setSelected={props.setSelected}
        canEdit={false}
      />
      {props.labels.map((label, index) => {
        return (
          <FormControl id={label} key={label}>
            <FormLabel color="white">{label}</FormLabel>
            <StyledInput
              data-testid={label}
              value={props.states[index][0]}
              onChange={setInput(props.states[index][1])}
            />
          </FormControl>
        );
      })}
      <Box pt={2}>
        <DarkMode>
          <Flex justify="space-between">
            {props.delete !== undefined ? (
              <Button
                variant="outline"
                colorScheme="red"
                rounded="none"
                onClick={props.delete}
              >
                Delete
              </Button>
            ) : (
              <Box></Box>
            )}

            <Button
              variant="outline"
              colorScheme="green"
              rounded="none"
              onClick={props.onSave}
            >
              Save
            </Button>
          </Flex>
        </DarkMode>
      </Box>
    </Stack>
  );
};

export default EditField;
