import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import React, { useContext } from "react";
import { auth } from "..";
import { DemoContext } from "../App";

export const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [demo, setDemo] = useContext(DemoContext);
  return (
    <Box m={3} d="flex" justifyContent="space-between" w="full">
      <Heading>Securit</Heading>
      <HStack>
        <Button onClick={toggleColorMode}>
          {colorMode === "dark" ? "Light" : "Dark"} Mode
        </Button>
        <IfFirebaseUnAuthed>
          {() => (
            <>
              {demo ? (
                <Button onClick={() => setDemo(false)}>Log Out</Button>
              ) : (
                <Button onClick={() => setDemo(true)}>Demo</Button>
              )}
            </>
          )}
        </IfFirebaseUnAuthed>

        <IfFirebaseAuthed>
          {() => (
            <Button data-testid="signOut" onClick={() => auth.signOut()}>
              Log Out
            </Button>
          )}
        </IfFirebaseAuthed>
      </HStack>
    </Box>
  );
};
