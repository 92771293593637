import { Box, Stack } from "@chakra-ui/react";
import React, { createContext, useState } from "react";
import { Navbar } from "../Components/Navbar";
import { Accounts } from "./Accounts";

export const CategoryContext = createContext<
  [string[], React.Dispatch<React.SetStateAction<string[]>>]
>([[], () => {}]);

export const Home = () => {
  const [value, setValue] = useState<string[]>([]);
  return (
    <Box d="flex" justifyContent="center">
      <Stack w="full" maxW="3xl" align="center" px={5}>
        <Navbar />
        <CategoryContext.Provider value={[value, setValue]}>
          <Accounts />
        </CategoryContext.Provider>
      </Stack>
    </Box>
  );
};
