import { Box, Collapse, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconList } from "react-fa-icon-picker";
import { isLight } from "../utils";
import * as Fa from "react-icons/fa";

type CardProps = {
  title?: string;
  iconId?: IconList;
  color: string;
  children?: ReactNode;
  w?: string;
  isOpen?: boolean;
  onToggle: () => void;
  rightIcon?: IconList;
  onIconClick?: () => void;
};
const Card = (props: CardProps) => {
  const fontColor = isLight(props.color) ? "black" : "white";

  return (
    <Stack w={props.w} textAlign="left" d="flex" mb={3} spacing={0}>
      <Box d="flex">
        <Box
          w="full"
          d="flex"
          as="button"
          onClick={props.onToggle}
          _focus={{ outline: "none" }}
          _active={{ opacity: ".8" }}
          alignItems="stretch"
        >
          <Box p={4} bg="black" d="flex" alignItems="center">
            <Icon
              as={props.iconId ? Fa[props.iconId] : Fa["FaAngry"]}
              color={props.color}
            />
          </Box>
          <Box
            pl={4}
            bg={props.color}
            w="full"
            d="flex"
            alignItems="center"
            alignContent="center"
          >
            <Text fontSize="xl" color={fontColor}>
              {props.title}
            </Text>
          </Box>
        </Box>
        {props.rightIcon && (
          <Flex
            alignItems="center"
            as="button"
            bg={props.color ?? "purple.800"}
            p={4}
            data-testid="edit_button"
            onClick={props.onIconClick}
          >
            <Icon as={Fa[props.rightIcon]} color={fontColor} />
          </Flex>
        )}
      </Box>
      <Collapse in={props.isOpen} animateOpacity>
        <Box bg="black" p={4}>
          {props.children}
        </Box>
      </Collapse>
    </Stack>
  );
};
export default Card;
