import { Flex, Stack, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { auth } from "..";
import { useWindowSize } from "../hooks/useWindowSize";
import { AccountDataType } from "../types";
import { getAccounts, getCategories } from "../dbHandler";
import NewAccountCard from "./NewAccountCard";
import AccountCard from "./AccountCard";
import { Categories } from "./Categories";
import { CategoryContext } from "./Home";

const demoAccounts: AccountDataType[] = [
  {
    categories: ["social media", "private"],
    color: "#2196f3",
    iconId: "FaTwitter",
    name: "Twitter",
    password: "example.password",
    uid: "1",
    username: "username",
  },
  {
    categories: ["social media", "private"],
    color: "#3f51b5",
    iconId: "FaFacebookF",
    name: "Facebook",
    password: "example.password",
    uid: "2",
    username: "username",
  },
  {
    categories: ["private"],
    color: "#e91e63",
    iconId: "FaMailBulk",
    name: "E-Mail",
    password: "example.password",
    uid: "3",
    username: "username",
  },
  {
    categories: ["work"],
    color: "#607d8b",
    iconId: "FaDesktop",
    name: "Work Account",
    password: "example.password",
    uid: "4",
    username: "username",
  },
];
const demoCategories: string[] = ["social media", "work", "private"];

export const Accounts = () => {
  const [accounts, setAccounts] = useState<AccountDataType[]>();
  const size = useWindowSize();
  const [filteredAccounts, setFilteredAccounts] = useState<AccountDataType[]>(
    []
  );

  const [selected, setSelected] = useState<string[]>([]);
  const [, setCategories] = useContext(CategoryContext);

  useEffect(() => {
    if (!auth.currentUser) {
      setCategories(demoCategories);
      setAccounts(demoAccounts);
      return;
    }
    getAccounts(auth.currentUser.uid).then((newAccounts) => {
      if (newAccounts.length > 0) {
        const acc = newAccounts.sort((a, b) => (a.name < b.name ? -1 : 1));
        setAccounts(acc);
      }
    });
    getCategories(auth.currentUser.uid).then((c) => {
      setCategories(c);
    });
  }, [setCategories]);

  useEffect(() => {
    if (!accounts) return;
    if (selected.length === 0) return setFilteredAccounts(accounts);
    setFilteredAccounts(
      accounts.filter((acc) =>
        acc.categories.some((c) => selected.indexOf(c) > -1)
      )
    );
  }, [selected, accounts]);

  return (
    <Stack w="full">
      <NewAccountCard accounts={accounts} setAccounts={setAccounts} />
      <Categories
        selected={selected}
        setSelected={setSelected}
        canEdit={true}
      />
      {filteredAccounts && size.width > 768 && filteredAccounts.length > 1 ? (
        <Flex wrap="wrap" w="full" justifyContent="space-between">
          <VStack>
            {filteredAccounts.map((account, i) => {
              if (i % 2 === 0)
                return (
                  <AccountCard
                    key={account.uid}
                    account={account}
                    accounts={accounts || []}
                    setAccounts={setAccounts}
                  />
                );
              return "";
            })}
          </VStack>
          <VStack>
            {filteredAccounts.map((account, i) => {
              if (i % 2 === 1)
                return (
                  <AccountCard
                    key={account.uid}
                    account={account}
                    accounts={accounts || []}
                    setAccounts={setAccounts}
                  />
                );
              return "";
            })}
          </VStack>
        </Flex>
      ) : (
        <Stack w="full">
          {filteredAccounts?.map((account) => {
            return (
              <AccountCard
                account={account}
                key={account.uid}
                fullW={true}
                accounts={filteredAccounts}
                setAccounts={setAccounts}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
